html,
body,
#root {
  height: 100%;
  margin: 0px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
